import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import * as modalActions from '../../../redux/actions/modal-actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as Icons from '@mui/icons-material';
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Autocomplete,
  Typography,
  Badge,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  doc,
  collectionGroup,
  getCountFromServer,
  where,
  query,
} from 'firebase/firestore';
import { db } from '../../../firebase';
import { TextField as TextFieldMUI } from '@mui/material';
import { useDrop } from 'react-dnd';
import nodeAxiosFirebase from '../../../utils/nodeAxiosFirebase';
import TextField from '../../../stories/general-components/TextField';
import getRandomString from '../../../utils/getRandomString';
import EditModule from './EditModule';
import GeneralText from '../../../stories/general-components/GeneralText';
import {
  setCurrentSection,
  setGeneralStatus,
} from '../../../redux/actions-v2/coreAction';
import DrawerSide from '../../../stories/layout-components/DrawerSide';
import Loading from '../../../stories/general-components/Loading';
import moment from 'moment';
import Modules from './Modules';
import AppointmentsIcon from '../../../components/@generalComponents/layout/Icons/AppointmentsIcon';
import DailyManagementIcon from '../../../components/@generalComponents/layout/Icons/DailyManagementIcon';
import TicketIcon from '../../../components/@generalComponents/layout/Icons/TicketIcon';
import FormsIcon from '../../../components/@generalComponents/layout/Icons/FormsIcon';
import ArticleIcon from '../../../components/@generalComponents/layout/Icons/ArticleIcon.js';
import LocationsManagementIcon from '../../../components/@generalComponents/layout/Icons/LocationsManagement.js';

const PagesNav = ({
  isTablet,
  openSideTasksAgenda,
  slots,
  tasksOpen,
  drawerOpen,
  setDrawerOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname: currentPath } = useLocation();
  const { moduleName, moduleId } = useParams();
  const { t, i18n } = useTranslation();
  const currentlangCode = i18n.language;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [drawerOpenModules, setDrawerOpenModules] = useState(false);
  const [drawerType, setDrawerType] = useState('');
  const [moduleSelected, setModuleSelected] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);

  const [dropName, setDropName] = useState('');
  const [groups, setGroups] = useState([]);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [countSlots, setCountSlots] = useState(0);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const businessStructures = businessStructure?.structures || [];
  const modules = businessStructure?.modules || [];
  const currentSectionState = useSelector((state) => state.core.currentSection);
  const activeMenu = useSelector((state) => state.core.activeMenu);
  const notifs = useSelector((state) => state.core.notifs);
  const activeModule = modules?.find((m) => m.id === moduleId);

  const nodes = useSelector((state) => state.core.nodes);
  const currentUser = useSelector((state) => state.core.user);

  const currentUserGroups = currentUser?.activeBusiness?.groups || [];

  const formatedGroups = businessPreference?.groups?.map((group) => ({
    label: group?.name,
    value: group?.identifiant,
    id: group?.identifiant,
  }));

  const handleSectionChange = useCallback((section) => {
    dispatch(setCurrentSection(section));
  }, []);

  useEffect(() => {
    if (businessPreference?.id && currentUser?.uid) {
      const getCount = async () => {
        const userRef = doc(db, 'users', currentUser?.uid);
        const businessRef = doc(db, 'businessesOnNode', businessPreference?.id);
        try {
          const coll = query(
            collectionGroup(db, 'slots'),
            where('isDone', '==', false),
            where('userId', '==', userRef),
            where('ownerId', '==', businessRef),
            where('targetDate', '==', moment().format('YYYY-MM-DD'))
          );
          const snapshot = await getCountFromServer(coll);
          setCountSlots(snapshot.data().count);
        } catch (error) {
          console.error(error);
        }
      };
      getCount();
    }
  }, [businessPreference?.id, currentUser?.uid]);

  const financesCollection =
    currentPath === '/app/finances/cards' ? 'cardsuninvoiced' : 'cardsinvoiced';

  const handleCloseDrawer = () => {
    setDrawerOpenModules(false);
  };

  const addToDrop = async (item, node) => {
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'addElement-kanban',
          type: 'pulse',
        })
      );
      setDisplayLoading(true);
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `drops-addElement`,
        body: {
          name: item?.name,
          status: item?.status || 0,
          structureId: item?.structureIdentifiant,
          dropId: node?.dropId,
          elementPath: item?.documentPath,
          collectionField: moduleName || financesCollection,
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'addElement-kanban',
          type: 'pulse',
        })
      );
      setDisplayLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  function DroppableNode({ node, navigateWS, isDarkMode, index }) {
    const [, drop] = useDrop(() => ({
      accept: 'item',
      drop: (item, monitor) => {
        addToDrop(item?.element, node);
      },
    }));

    return (
      <div
        className="d-flex justify-content-between py-2 hover"
        style={{
          // position: 'relative',
          backgroundColor: 'transparent',
        }}
        key={index + 'node'}
        ref={drop}
        onClick={() => navigateWS(node?.path)}
      >
        {isTablet ? (
          <div className="align-c" style={{ width: '100%' }}>
            <div style={{ fontSize: '18px' }}>{node?.emoji || '😀'}</div>
          </div>
        ) : (
          <div className="d-flex">
            <div style={{ fontSize: '18px' }}>{node?.emoji || '😀'}</div>
            <div className="d-flex">
              <Typography
                fontWeight={400}
                fontSize="13.5px"
                color={isDarkMode ? '#FFF' : '#000'}
                variant="body2"
                sx={{
                  marginLeft: '12px',
                  marginTop: '3px',
                  marginRight: '5px',
                  cursor: 'pointer',
                  '&:hover': {
                    fontWeight: 500,
                  },
                }}
              >
                {node?.name || ''}
              </Typography>
            </div>
          </div>
        )}
        {!isTablet && (
          <div
            style={{
              color: businessPreference?.mainColor,
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
              fontWeight: 600,
              fontSize: '14px',
              width: '20px',
              marginTop: '3px',
              marginRight: '12px',
              height: '15px',
              borderRadius: '50%',
            }}
          >
            {node?.count}
          </div>
        )}
        {node?.seenLast &&
          node?.seenLast?.length !== 0 &&
          currentUser?.uid &&
          !node?.seenLast?.includes(currentUser?.uid) && (
            <Badge
              color="error"
              variant="dot"
              sx={{
                position: 'absolute',
                marginTop: '11px',
                right: '0',
                marginRight: '4px',
                zIndex: 10000,
              }}
            ></Badge>
          )}
      </div>
    );
  }

  const businessModules = businessPreference?.menu
    ?.filter((module) => module?.section === currentSectionState)
    .sort((a, b) => a.order - b.order);

  const openAddElementModal = (structureId) => {
    dispatch(
      modalActions.modalElementCreation({
        isOpen: true,
        structureId: structureId,
      })
    );
    handleMenuClose();
  };

  const navigateWS = (node) => {
    navigate('/app/nodes/' + node?.dropId + '?tab=0');
  };

  const handleEditModule = (module) => {
    setDrawerType('module');
    setDrawerOpenModules(true);

    const selectedModule = modules?.find((m) => m?.id === module?.id);
    setModuleSelected(selectedModule);
  };

  const handleDeleteModule = async () => {
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'deleteModule',
          type: 'pulse',
        })
      );
      if (moduleSelected?.id) {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `business-setModuleStructure`,
          body: {
            type: 'deleteModule',
            moduleId: moduleSelected?.id,
          },
        });
      }
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'deleteModule',
          type: 'pulse',
        })
      );
      setModuleSelected({});
      setDrawerOpenModules(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteDrop = async () => {};

  const resolveMenuIcon = (menuName) => {
    switch (menuName) {
      case 'OPERATIONS':
        return <TicketIcon />;
      case 'FINANCES':
        return <FormsIcon />;
      case 'NODE':
        return <DailyManagementIcon />;
      case 'TOOLS':
        return <LocationsManagementIcon />;
      case 'MARKETING':
        return <ArticleIcon />;
      case 'SETTINGS':
        return <AppointmentsIcon />;
      default:
        return <LocationsManagementIcon />;
    }
  };

  const handleSaveModule = async () => {
    dispatch(setGeneralStatus('loading'));
    setDisplayLoading(true);
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'menu-editModule',
          type: 'pulse',
        })
      );
      if (moduleSelected?.id) {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `business-setModuleStructure`,
          body: {
            moduleId: moduleSelected?.id,
            lang: currentlangCode,
            structureId: moduleSelected?.structureId,
            type: 'general',
            data: {
              icon: moduleSelected?.icon,
              name: moduleSelected?.name,
              groups: moduleSelected?.groups,
              section: moduleSelected?.section,
              order: moduleSelected?.order,
              segments: moduleSelected?.segments,
              refresh: moduleSelected?.list?.preferences?.refresh,
              limit: moduleSelected?.list?.preferences?.limit,
            },
          },
        });
      } else {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `business-setModuleStructure`,
          body: {
            lang: currentlangCode,
            structureId: moduleSelected?.structureId,
            type: 'addModule',
            data: {
              icon: moduleSelected?.icon,
              name: moduleSelected?.name,
              groups: moduleSelected?.groups,
              section: moduleSelected?.section,
              order: moduleSelected?.order,
              segments: moduleSelected?.segments,
              refresh: moduleSelected?.list?.preferences?.refresh,
              limit: moduleSelected?.list?.preferences?.limit,
            },
          },
        });
      }
      setModuleSelected({});
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'menu-editModule',
          type: 'pulse',
        })
      );
      setDisplayLoading(false);
      setDrawerOpenModules(false);
    } catch (error) {
      console.error(error);
    }
  };

  const RestrictedModule = ({ children }) => {
    if (
      currentSectionState === 'SETTINGS' ||
      currentSectionState === 'TOOLS' ||
      currentSectionState === 'NODE'
    ) {
      return null;
    }
    return children;
  };

  const handleNewModule = () => {
    setDrawerType('module');
    setDrawerOpenModules(true);
    setModuleSelected({ isNew: true });
    setDropName('');
    setGroups([]);
  };

  const handleOpenDrop = () => {
    setDrawerType('drop');
    setDropName('');
    setGroups([]);
    setDrawerOpenModules(true);
  };

  const saveDrop = async () => {
    setDrawerOpenModules(false);
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'menu-saveDrop',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        showLoading: true,
        url: `drops-setDrop`,
        body: {
          name: dropName,
          emoji: '📚',
          groups: groups?.map((group) => group.id),
        },
      });
      setDropName('');
      setGroups([]);
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'menu-saveDrop',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const notifsCountFiltred =
    notifs?.notifs?.length > 0 &&
    notifs?.notifs?.filter((notif) => {
      return notif?.isViewed === false;
    })?.length;

  return (
    <div>
      <DrawerSide
        isLoading={displayLoading}
        position="right"
        isDrawerOpen={drawerOpenModules}
        isCreation={
          (drawerType === 'module' && moduleSelected?.isNew) ||
          drawerType !== 'module'
        }
        title={
          drawerType === 'module'
            ? moduleSelected?.isNew
              ? t('addModule')
              : moduleSelected?.name
            : t('addDrop')
        }
        handleRemove={
          drawerType === 'module' ? handleDeleteModule : handleDeleteDrop
        }
        handleSave={drawerType === 'module' ? handleSaveModule : saveDrop}
        subtitle={
          drawerType === 'module'
            ? moduleSelected?.isNew
              ? t('newModule')
              : t('editModule')
            : t('newDrop')
        }
        handleDrawerClose={handleCloseDrawer}
      >
        {drawerType === 'module' ? (
          <div>
            <EditModule
              module={moduleSelected}
              setModuleSelected={setModuleSelected}
            />
          </div>
        ) : (
          <div>
            <div className="mb-3">
              <TextField
                label={t('name')}
                value={dropName}
                onChange={(e) => setDropName(e.target.value)}
                fullWidth
              />
            </div>
            <Autocomplete
              multiple
              key={getRandomString(5)}
              options={formatedGroups || []}
              getOptionLabel={(option) => option?.label || ''}
              value={groups}
              onChange={(event, newValue) => {
                setGroups(newValue);
              }}
              renderInput={(params) => (
                <TextFieldMUI
                  {...params}
                  label={t('selectGroup')}
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '14px',
                      '&.Mui-focused fieldset': {
                        borderColor: businessPreference?.mainColor || '#000',
                        boxShadow: `0 0 0 0.2rem ${
                          businessPreference?.mainColor + '20'
                        }`,
                      },
                    },
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>
        )}
      </DrawerSide>
      <div
        style={{
          paddingRight: isTablet ? '' : '16px',
          paddingLeft: isTablet ? '' : '16px',
          marginLeft: isTablet ? '-10px' : '',
          marginTop: isTablet ? '8px' : '',
        }}
        className={isTablet ? 'mb-3 align-c' : 'mb-4 px-1 d-flex'}
      >
        <Button
          onClick={handleMenuOpen}
          className="middle-content"
          fullWidth={isTablet ? false : true}
          sx={{
            marginLeft: '10px',
            width: isTablet ? '32px' : '190px',
            maxWidth: isTablet ? '32px !important' : '190px',
            minWidth: isTablet ? '32px !important' : '190px',
            height: isTablet ? '32px' : '42px',
            backgroundColor: isDarkMode
              ? businessPreference?.mainColor + '20'
              : businessPreference?.mainColor + '20',
            borderRadius: '10px',
            padding: isTablet ? '0.4rem' : '0.8rem',
            '&:hover': {
              boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.1)',
            },
          }}
        >
          <Icons.AddOutlined
            fontSize="medium"
            htmlColor={isDarkMode ? '#FFF' : '#000'}
            sx={{
              marginRight: isTablet ? '0px' : '10px',
            }}
          />
          {!isTablet && (
            <Typography
              fontWeight={600}
              fontSize="13px"
              color={isDarkMode ? '#FFF' : '#000'}
              variant="body2"
            >
              {t('new')}
            </Typography>
          )}
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          elevation={3}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '12px',
            },
          }}
        >
          {businessStructures
            ?.slice()
            .sort((a, b) => a[`name`]?.localeCompare(b[`name`]))
            ?.filter(
              (structure) => structure?.element?.preferences?.creation !== false
            )
            ?.map((structure) => {
              const IconComponent = Icons[structure?.icon] || Icons.HelpOutline;
              return (
                <MenuItem
                  divider
                  dense
                  key={structure?.id + 'structure'}
                  onClick={() => openAddElementModal(structure?.id)}
                >
                  <div className="middle-content d-flex">
                    <IconComponent color="secondary" fontSize="small" />
                    <div className="px-3">{structure?.name}</div>
                  </div>
                </MenuItem>
              );
            })}
        </Menu>
      </div>

      <div className="mt-4" style={{ paddingLeft: isTablet ? '' : '16px' }}>
        <div key={module.id + 'modulelist'}>
          <div
            onClick={() => navigate('/app/dashboard')}
            style={{ marginLeft: isTablet ? '-8px' : '' }}
            className={`${
              isTablet ? 'd-flex align-c' : 'd-flex'
            } middle-content hover`}
          >
            <div className="align-c">
              <Icons.DashboardCustomizeOutlined
                fontSize="medium"
                htmlColor={
                  currentPath === '/app/dashboard'
                    ? isDarkMode
                      ? '#FFF'
                      : `${businessPreference?.mainColor}`
                    : isDarkMode
                    ? '#FFF'
                    : '#000'
                }
                sx={{
                  fontSize: '21px',
                  paddingRight: isTablet ? '1px' : '3px',
                }}
              />
            </div>
            {!isTablet && (
              <div className="row justify-content-between">
                <div className="col-10 align-left px-4">
                  <Typography
                    fontWeight={currentPath === '/app/dashboard' ? 600 : 400}
                    fontSize="13.5px"
                    sx={{
                      '&:hover': {
                        fontWeight: 600,
                      },
                    }}
                    color={
                      currentPath === '/app/dashboard'
                        ? isDarkMode
                          ? '#FFF'
                          : `${businessPreference?.mainColor}`
                        : isDarkMode
                        ? '#FFF'
                        : '#000'
                    }
                    variant="body2"
                  >
                    {t('dashboards')}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </div>
        {businessPreference?.preferences?.inbox && (
          <>
            <div
              style={{
                paddingTop: '0.2rem',
                paddingBottom: '0.3rem',
                marginLeft: isTablet ? '-8px' : '',
              }}
              key={module.id + 'module'}
            >
              <div
                onClick={() => navigate('/app/inbox')}
                className={`${
                  isTablet ? 'd-flex align-c' : 'd-flex'
                } middle-content hover`}
              >
                <div className="align-c d-flex">
                  <Icons.InboxOutlined
                    fontSize="medium"
                    htmlColor={
                      currentPath === '/app/inbox'
                        ? isDarkMode
                          ? '#FFF'
                          : `${businessPreference?.mainColor}`
                        : isDarkMode
                        ? '#FFF'
                        : '#000'
                    }
                    sx={{
                      fontSize: '21px',
                      marginTop: '1px',
                      paddingRight: isTablet ? '1px' : '3px',
                    }}
                  />{' '}
                  {isTablet && (
                    <div className="align-right">
                      {notifsCountFiltred > 0 && (
                        <Badge
                          sx={{
                            position: 'absolute',
                            marginTop: '5px',
                            marginRight: '4px',
                            right: '0',
                          }}
                          color="error"
                          variant="dot"
                        ></Badge>
                      )}
                    </div>
                  )}
                </div>
                {!isTablet && (
                  <div className="row justify-content-between">
                    <div className="align-left px-4">
                      <Typography
                        fontWeight={currentPath === '/app/inbox' ? 600 : 400}
                        fontSize="13.5px"
                        color={
                          currentPath === '/app/inbox'
                            ? isDarkMode
                              ? '#FFF'
                              : `${businessPreference?.mainColor}`
                            : isDarkMode
                            ? '#FFF'
                            : '#000'
                        }
                        sx={{
                          '&:hover': {
                            fontWeight: 600,
                          },
                        }}
                        variant="body2"
                      >
                        {t('inbox')}
                      </Typography>
                    </div>
                    <div className="align-right">
                      {notifsCountFiltred > 0 && (
                        <Badge
                          sx={{
                            position: 'absolute',
                            marginTop: '-8px',
                            marginRight: '16px',
                            right: '0',
                          }}
                          color="error"
                          variant="dot"
                        ></Badge>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {(businessPreference?.formula === 'rise' ||
          businessPreference?.formula === 'trial') && (
          <>
            <div
              style={{
                paddingTop: '0.2rem',
                paddingBottom: '0.2rem',
                marginLeft: isTablet ? '-8px' : '',
              }}
              key={module.id + 'module'}
            >
              <div
                onClick={openSideTasksAgenda}
                className={`${
                  isTablet ? 'd-flex align-c' : 'd-flex'
                } middle-content hover`}
              >
                <div className="align-c">
                  <Icons.EventAvailableOutlined
                    fontSize="medium"
                    htmlColor={isDarkMode ? '#FFF' : '#000'}
                    sx={{
                      fontSize: '21px',
                      paddingRight: isTablet ? '1px' : '3px',
                      marginTop: '2px',
                    }}
                  />
                </div>
                {!isTablet && (
                  <div className="row d-flex justify-content-between">
                    <div className="align-left px-4">
                      <Typography
                        fontWeight={tasksOpen ? 600 : 400}
                        fontSize="13.5px"
                        sx={{
                          '&:hover': {
                            fontWeight: 600,
                          },
                        }}
                        color={isDarkMode ? '#FFF' : '#000'}
                        variant="body2"
                      >
                        {t('myToDo')}
                      </Typography>
                    </div>
                  </div>
                )}
                <div className="align-right">
                  {countSlots > 0 && !isTablet && (
                    <div
                      style={{
                        color: businessPreference?.mainColor,
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        fontWeight: 600,
                        fontSize: '12px',
                        width: '20px',
                        marginTop: '3px',
                        marginRight: '6px',
                        height: '15px',
                        borderRadius: '50%',
                      }}
                    >
                      {countSlots || ''}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Divider component="div" className="mt-2 mb-2" />
      <div
        className="align-c"
        style={{
          paddingLeft: isTablet ? '' : '10px',
        }}
      >
        {!isTablet && (
          <div className="row justify-content-between py-2">
            <div className="col-9 align-left mx-2">
              <TextFieldMUI
                select
                value={currentSectionState}
                variant="standard"
                sx={{
                  marginTop: '-6px',
                  '.MuiInputBase-input': {
                    border: 'none',
                    fontSize: '13px',
                    fontWeight: 600,
                    paddingTop: '6px',
                  },
                  '& .MuiFormLabel-root': {
                    border: 'none',
                  },
                  '& .MuiInput-underline:before': {
                    border: 'none',
                  },
                  '& .MuiInput-underline:after': {
                    border: 'none',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    border: 'none',
                  },
                  '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
                    border: 'none',
                  },
                }}
              >
                {activeMenu?.map((section) => (
                  <MenuItem
                    key={section?.name}
                    value={section?.name}
                    sx={{
                      width: '220px',
                    }}
                    onClick={() => handleSectionChange(section?.name)}
                  >
                    <div className="d-flex middle-content">
                      <div style={{ width: '20px', height: '20px' }}>
                        {resolveMenuIcon(section?.name)}
                      </div>
                      <div className="mx-3">
                        <GeneralText
                          text={section?.['name_' + currentlangCode]}
                          fontSize="16px"
                          size="bold"
                          primary={true}
                        />
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </TextFieldMUI>
            </div>
            <div className="col-2 align-right">
              {currentUser?.activeBusiness?.role !== 'EMPLOYEE' && (
                <RestrictedModule>
                  <Icons.AddOutlined
                    className="hover mt-1"
                    fontSize="10px"
                    onClick={handleNewModule}
                  />
                </RestrictedModule>
              )}
            </div>
          </div>
        )}

        <Modules
          businessModules={businessModules}
          isTablet={isTablet}
          isDarkMode={isDarkMode}
          activeModule={activeModule}
          businessPreference={businessPreference}
          navigate={navigate}
          handleEditModule={handleEditModule}
          currentSectionState={currentSectionState}
        />
        <Divider component="div" className="mt-2 mb-2" />
        {(businessPreference?.formula === 'rise' ||
          businessPreference?.formula === 'trial') && (
          <div
            style={{ marginLeft: isTablet ? '-8px' : '' }}
            className={isTablet ? 'align-left' : 'align-left px-2'}
          >
            <div>
              {!isTablet && (
                <div className="d-flex justify-content-between py-2">
                  <GeneralText
                    text={t('workspaces')}
                    fontSize="12px"
                    size="bold"
                    primary={true}
                  />

                  {currentUser?.activeBusiness?.role !== 'EMPLOYEE' && (
                    <Icons.AddOutlined
                      className="hover mt-1"
                      fontSize="10px"
                      onClick={handleOpenDrop}
                    />
                  )}
                </div>
              )}
              {displayLoading && !drawerOpenModules ? (
                <Loading size="small" type="logo" />
              ) : (
                <div>
                  {nodes
                    ?.filter((node) =>
                      node?.groups?.some((group) =>
                        currentUserGroups?.includes(group)
                      )
                    )
                    ?.map((node, index) => (
                      <DroppableNode
                        key={index}
                        index={index}
                        node={node}
                        navigateWS={() => navigateWS(node)}
                        isDarkMode={isDarkMode}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PagesNav;
