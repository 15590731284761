import React, { useState, useCallback, useEffect } from 'react';
import chroma from 'chroma-js';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as modalActions from '../redux/actions/modal-actions';
import { useTranslation } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAuth, signOut } from 'firebase/auth';
import { useTheme } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import NodeAIIcon from '../components/@generalComponents/layout/NodeAIIcon.jsx';
import * as sidebarActions from '../redux/actions/sidebar-actions';
import {
  ListItemText,
  Menu,
  MenuItem,
  SpeedDial,
  SpeedDialAction,
} from '@mui/material';
import AllModalsRoot from '../modals';
import AllSidebarsRoot from '../sidebars';
import AllDrawersRoot from '../drawers';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import PagesNav from '../components/@generalComponents/layout/PagesNav.jsx';

import '../components/@generalComponents/layout/layout.css';
import {
  clearBusinessData,
  fetchBusinessData,
  pageLoaded,
  setCurrentSection,
  setOnCall,
} from '../redux/actions-v2/coreAction.js';
import { clearAllLists } from '../redux/actions-v2/listAction.js';
import DialogWindow from '../stories/general-components/DialogWindow.jsx';
import AgendaSidebar from '../sidebars/AgendaSidebar.jsx';
import LogRocket from 'logrocket';
import TopNavBar from './components/TopNavBar.jsx';
import {
  LogoutOutlined,
  Person2Outlined,
  QrCodeScannerOutlined,
  Widgets,
} from '@mui/icons-material';
import UserSecMenu from '../components/@generalComponents/layout/UserSecMenu.jsx';

const MainLayoutV2 = ({
  children,
  pageTitle,
  tabs,
  actions,
  sectionTitle,
  formatedPath,
  elementId,
  searchActivated,
  manualIndex,
  subTitle,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { moduleName, moduleId, structureId } = useParams();

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [dialogOpen, setDialogOpen] = useState(false);
  const businessesStorage = sessionStorage.getItem('businesses');
  const businesses = JSON.parse(businessesStorage);

  const [taskOpen, setTaskOpen] = useState(false);
  const [slots, setSlots] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElSec, setAnchorElSec] = useState(null);

  const isDarkMode = theme.palette.mode === 'dark';

  const softwareVersion = process.env.REACT_APP_VERSION;

  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const currentUser = useSelector((state) => state.core.user);
  const activeUser = businessPreference?.employees?.find(
    (employee) => employee.id === currentUser?.uid
  );
  const businessModules = businessStructure?.modules;

  const mainColor = businessPreference?.mainColor || '#000000';
  const secColor = businessPreference?.secColor || '#000000';
  const lightGreyScale = chroma
    .scale([mainColor, '#f2f2f2'])
    .mode('lab')
    .colors(10)
    .map((color) => chroma(color).brighten(1.5));
  const lightGreyScaleSec = chroma
    .scale([secColor, '#f2f2f2'])
    .mode('lab')
    .colors(10)
    .map((color) => chroma(color).brighten(1.5));

  const openAIModal = () => {
    dispatch(
      sidebarActions.nodeAiSidebar({
        show: true,
        isOpen: true,
      })
    );
  };

  const handleMenuOpenSec = (event) => {
    setAnchorElSec(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElSec(null);
  };

  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      navigate('/signin');
    } catch (error) {
      console.error(error);
    }
  };

  LogRocket.init('hpp7xp/node');

  if (currentUser) {
    LogRocket.identify(currentUser?.uid, {
      name: currentUser?.displayName,
      email: currentUser?.email,
      subscriptionType: businessPreference?.formula,
    });
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.usetiful.com/dist/usetiful.js';
    script.async = true;
    script.id = 'usetifulScript';
    script.dataset.token = '37bc3ab9d14a22401b6a739274c457f8';
    document.head.appendChild(script);

    const userSegmentationScript = document.createElement('script');
    userSegmentationScript.innerHTML = `
      window.usetifulTags = { 
        userId: "${currentUser?.uid + '_' + businessPreference?.id || ''}", 
        firstName: "${currentUser?.displayName || ''}", 
        lastName: "${currentUser?.publicDisplay?.lastName || ''}", 
        language: "${currentLanguage || 'fr'}", 
        segment:"${businessPreference?.formula || ''}",
        role: "${activeUser?.role || ''}"
      };
    `;
    document.head.appendChild(userSegmentationScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(userSegmentationScript);
    };
  }, [currentUser, currentLanguage]);

  const openModalScan = () => {
    dispatch(
      modalActions.modalScan({
        isOpen: true,
      })
    );
  };

  const actionsSpeed = [
    {
      name: t('scan'),
      icon: <QrCodeScannerOutlined />,
      onClick: openModalScan,
    },
  ];

  if (businessPreference?.formula?.toUpperCase() === 'RISE') {
    actionsSpeed.push({
      name: t('nodeAI'),
      icon: <NodeAIIcon size={26} />,
      onClick: openAIModal,
    });
  }

  const handleBusinessSelect = (business) => {
    localStorage.setItem('section', 'OPERATIONS');
    dispatch(setOnCall(false));
    dispatch(setCurrentSection('OPERATIONS'));
    dispatch(clearAllLists());
    dispatch(clearBusinessData());
    sessionStorage.setItem('businessToken', business?.token);
    localStorage.setItem('businessId', business?.businessId);
    dispatch(fetchBusinessData(business?.businessId, t, currentLanguage));
    setDialogOpen(false);
    navigate('/app/dashboard');
  };

  const handleChangeBusiness = () => {
    setDialogOpen(true);
  };

  const windowHeight = window.innerHeight;
  const contentHeight = windowHeight - 76;

  const openSideTasksAgenda = () => {
    setTaskOpen(!taskOpen);
  };

  const getModuleName = () => {
    const module = businessModules?.find((m) => m.id === moduleId);

    if (pageTitle) {
      return pageTitle;
    }

    return module?.name;
  };

  useEffect(() => {
    if (businessModules) {
      setTimeout(() => {
        dispatch(pageLoaded(true));
      }, 3000);
    }
  }, [businessModules]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <DialogWindow
          title={t('switchAccount')}
          open={dialogOpen}
          size={'medium'}
          width={'medium'}
          onClose={() => setDialogOpen(false)}
        >
          <div>
            <div className="row">
              {businesses &&
                businesses?.map((business, idx) => (
                  <div
                    key={idx}
                    className="col-3 hover align-c p-1"
                    onClick={() => handleBusinessSelect(business)}
                  >
                    <div>
                      <img
                        style={{ width: '30px', height: '30px' }}
                        src={`https://storage.googleapis.com/node-business-logos/${business?.businessId}.png`}
                      />
                    </div>
                    <ListItemText
                      primary={business?.name}
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: isDarkMode ? '#FFF' : '#000',
                        fontSize: '10px',
                      }}
                      secondary={business?.city || '-'}
                      secondaryTypographyProps={{
                        variant: 'caption',
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </DialogWindow>

        <I18nextProvider i18n={i18n}>
          <Helmet>
            <title>
              {`Node -  ${getModuleName(pageTitle, currentLanguage)}` || 'Node'}
            </title>
            <meta name="robots" content="noindex" />
          </Helmet>
          <AllModalsRoot />
          <div
            style={{
              backgroundColor: 'rgba(230, 230, 244, 0.42)',
              height: '100vh',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <div
              style={{
                width: '500px',
                height: '500px',
                overflow: 'hidden',
                backgroundColor: lightGreyScale[0] + '50',
                borderRadius: '50%',
                filter:
                  activeUser?.preferences?.blur !== null
                    ? 'blur(' + activeUser?.preferences?.blur * 15 + 'px)'
                    : 'blur(120px)',
                position: 'absolute',
                zIndex: 0,
                bottom: '-220px',
                left: '-120px',
              }}
            ></div>
            <div
              style={{
                width: '500px',
                height: '500px',
                overflow: 'hidden',
                backgroundColor: lightGreyScaleSec[2] + '50',
                borderRadius: '50%',
                filter:
                  activeUser?.preferences?.blur !== null
                    ? 'blur(' + activeUser?.preferences?.blur * 15 + 'px)'
                    : 'blur(120px)',
                position: 'absolute',
                zIndex: 0,
                bottom: '120px',
                left: '-310px',
              }}
            ></div>
            <div
              style={{
                width: '700px',
                height: '200px',
                overflow: 'hidden',
                backgroundColor: '#FFFFFF',
                rotate: '325deg',
                borderRadius: '80px',
                filter:
                  activeUser?.preferences?.blur !== null
                    ? 'blur(' + activeUser?.preferences?.blur * 9 + 'px)'
                    : 'blur(120px)',
                position: 'absolute',
                zIndex: 0,
                bottom: '370px',
                left: '-220px',
              }}
            ></div>
            <div
              style={{
                width: '200px',
                height: '200px',
                overflow: 'hidden',
                backgroundColor: lightGreyScale[0] + '90',
                borderRadius: '50%',
                filter:
                  activeUser?.preferences?.blur !== null
                    ? 'blur(' + activeUser?.preferences?.blur * 15 + 'px)'
                    : 'blur(120px)',
                position: 'absolute',
                zIndex: 10,
                bottom: '-80px',
                left: '-80px',
              }}
            ></div>
            <div
              style={{
                width: '900px',
                height: '900px',
                overflow: 'hidden',
                backgroundColor: '#696969' + '15',
                borderRadius: '50%',
                filter:
                  activeUser?.preferences?.blur !== null
                    ? 'blur(' + activeUser?.preferences?.blur * 15 + 'px)'
                    : 'blur(120px)',
                position: 'absolute',
                zIndex: 0,
                top: '-220px',
                left: '-120px',
              }}
            ></div>
            <Grid
              sx={{
                border: `1px solid rgba(255, 255, 255, 0.3)`,
                padding: isTablet ? '8px' : '25px',
              }}
              container
            >
              <Grid
                sx={{
                  height: '95vh',
                  overflow: 'hidden',
                }}
                container
              >
                <SpeedDial
                  ariaLabel="Sections"
                  sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                  }}
                  icon={<Widgets />}
                >
                  {actionsSpeed?.map((action) => (
                    <SpeedDialAction
                      key={action?.name}
                      icon={action?.icon}
                      tooltipTitle={action.name}
                      onClick={action.onClick}
                    />
                  ))}
                </SpeedDial>

                <Grid
                  sx={{
                    height: '94vh',
                    borderRadius: '24px',
                    position: 'relative',
                  }}
                  xs={2.2}
                  sm={1}
                  md={2.2}
                  lg={2.2}
                  xl={2.2}
                  item
                >
                  <Paper
                    sx={{
                      backgroundColor: 'transparent',
                    }}
                    elevation={0}
                  >
                    <div>
                      <div className="align-c">
                        {businessPreference?.id && (
                          <div
                            className="mb-1 mt-2"
                            style={{
                              height: '6vh',
                              zIndex: 1000,
                              minHeight: isTablet ? '55px' : '65px',
                              position: 'relative',
                            }}
                          >
                            <img
                              src={`https://storage.googleapis.com/node-business-logos/${businessPreference?.id}.png`}
                              height={isTablet ? '35px' : '45px'}
                              width={isTablet ? '35px' : '45px'}
                              style={{ borderRadius: '50%' }}
                              className="hover mt-2"
                            />
                            <div
                              onClick={handleChangeBusiness}
                              className="hover"
                              style={{
                                position: 'absolute',
                                top: '3px',
                                right: '44%',
                                width: '40px',
                                height: '40px',
                                borderRadius: '30%',
                              }}
                            ></div>
                            <div
                              style={{
                                position: 'absolute',
                                top: '24px',
                                right: '34%',
                              }}
                            >
                              <UserSecMenu
                                drawerOpen={drawerOpen}
                                setDrawerOpen={setDrawerOpen}
                                handleMenuOpenSec={handleMenuOpenSec}
                              />
                              <Menu
                                anchorEl={anchorElSec}
                                open={Boolean(anchorElSec)}
                                onClose={handleMenuClose}
                                elevation={3}
                                sx={{
                                  '& .MuiPaper-root': {
                                    borderRadius: '12px',
                                  },
                                }}
                              >
                                <MenuItem
                                  divider
                                  dense
                                  key={'profile'}
                                  onClick={() => setDrawerOpen(true)}
                                >
                                  <div className="middle-content d-flex">
                                    <Person2Outlined
                                      color="secondary"
                                      fontSize="small"
                                    />
                                    <div className="px-3">{t('myProfile')}</div>
                                  </div>
                                </MenuItem>
                                <MenuItem
                                  divider
                                  dense
                                  key={'logout'}
                                  onClick={handleLogout}
                                >
                                  <div className="middle-content d-flex">
                                    <LogoutOutlined
                                      color="secondary"
                                      fontSize="small"
                                    />
                                    <div className="px-3">{t('logout')}</div>
                                  </div>
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                        )}

                        <div
                          style={{
                            height: '83vh',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            position: 'relative',
                            zIndex: 1000,
                          }}
                        >
                          <PagesNav
                            isTablet={isTablet}
                            slots={slots}
                            tasksOpen={taskOpen}
                            openSideTasksAgenda={openSideTasksAgenda}
                            drawerOpen={drawerOpen}
                            setDrawerOpen={setDrawerOpen}
                          />
                        </div>
                        <div
                          style={{
                            position: 'relative',
                            marginTop: '12px',
                          }}
                        >
                          <Typography
                            variant="caption"
                            fontSize={isTablet ? '8px' : '10px'}
                            color="#00000085"
                            lineHeight="1"
                            display="block"
                            fontWeight={600}
                            gutterBottom
                          >
                            Node™
                          </Typography>
                          <Typography
                            variant="caption"
                            fontSize={isTablet ? '6.5px' : '7px'}
                            color="#00000085"
                            lineHeight="1"
                            display="block"
                            gutterBottom
                          >
                            Version {softwareVersion} - Tous droits réservés.
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
                {taskOpen && (
                  <Grid
                    sx={{
                      borderRight: '1px solid rgba(0,0,0,0.1)',
                      position: 'relative',
                    }}
                    xs={12}
                    sm={2.8}
                    md={2.8}
                    lg={2.8}
                    xl={2.8}
                    item
                  >
                    <Paper
                      sx={{
                        backgroundColor: 'transparent',
                        height: '98vh',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        position: 'relative',
                      }}
                      elevation={0}
                    >
                      <AgendaSidebar slots={slots} setSlots={setSlots} />
                    </Paper>
                  </Grid>
                )}
                <Grid
                  xs={9.8}
                  sm={taskOpen ? 7 : 11}
                  md={taskOpen ? 7 : 9.8}
                  lg={taskOpen ? 7 : 9.8}
                  xl={taskOpen ? 7 : 9.8}
                  item
                  id="main-content"
                >
                  <Paper
                    elevation={0}
                    id="main-content-paper"
                    sx={{
                      zIndex: 1000,
                      height: '100vh',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <div>
                      <Paper
                        elevation={0}
                        sx={{
                          backgroundColor: 'transparent',
                        }}
                      >
                        <TopNavBar
                          isTablet={isTablet}
                          isMobile={isMobile}
                          pageTitle={pageTitle}
                          searchActivated={searchActivated}
                          actions={actions}
                          elementId={elementId}
                          formatedPath={formatedPath}
                          manualIndex={manualIndex}
                          tabs={tabs}
                          sectionTitle={sectionTitle}
                          subTitle={subTitle}
                        />

                        <AllSidebarsRoot />
                        <AllDrawersRoot />

                        <div
                          style={{
                            height: contentHeight + 'px',
                            overflowY: 'scroll',
                          }}
                        >
                          {children}
                        </div>
                      </Paper>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </I18nextProvider>
      </DndProvider>
    </div>
  );
};

export default MainLayoutV2;
