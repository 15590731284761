import React, { useEffect, useState } from 'react';
import {
  useParams,
  useSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import * as drawerActions from '../../redux/actions-v2/drawer-actions';

//utilities
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

//components
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import * as modalActions from '../../redux/actions/modal-actions';

import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import ElementDetailsContent from './ElementDetailsContent';
import TextField from '../../stories/general-components/TextField';
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  IconButton,
  Skeleton,
  Toolbar,
  Typography,
} from '@mui/material';
import TextFieldMUI from '@mui/material/TextField';
import { setSingleElementDetails } from '../../redux/actions-v2/elementAction';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';
import { toast } from 'react-toastify';

const ElementDetails = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { structureId, elementId, moduleName } = useParams();
  const pathname = location.pathname;
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [pageOpen, setPageOpen] = useState(false);
  const [accTabs, setAccTabs] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [tabDetailsOpen, setTabDetailsOpen] = useState(false);
  const [manualIndex, setManualIndex] = useState(null);
  const [elementData, setElementData] = useState({
    name: '-',
  });

  const [tabData, setTabData] = useState({
    groups: [],
    name_en: 'New Tab',
    name_fr: 'Nouvel Onglet',
  });

  const isDarkMode = theme.palette.mode === 'dark';
  const currentlangCode = i18n.language;
  const activeIndex = manualIndex || parseInt(searchParams.get('tab')) || 0;
  const businessPreference = useSelector((state) => state.core.businessData);

  const formattedGroups = businessPreference?.groups?.map((group) => ({
    label: group?.name,
    value: group?.identifiant,
    id: group?.identifiant,
  }));
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const businessStructures = businessStructure?.structures;
  const structure = businessStructures?.find((s) => s.id === structureId);

  const singleElement = useSelector(
    (state) => state.element.singleElementDetails
  );

  const currentCollection = useSelector(
    (state) => state.list.currentCollection
  );

  const structureIdInvoiced = businessStructures?.find(
    (s) => s.collectionField === 'cardsinvoiced'
  )?.id;

  const [layout, setLayout] = useState(structure?.tabs || []);

  const getDocument = async () => {
    try {
      setIsLoading(pageOpen ? false : true);
      let elementPath = null;
      if (moduleName === 'contacts') {
        const targetId = elementId?.split(businessPreference?.id)[0];
        elementPath = `users/${targetId}/connections/${elementId}`;
      } else if (
        moduleName === 'cardsinvoiced' ||
        moduleName === 'cardsuninvoiced' ||
        moduleName === 'cardsexpense'
      ) {
        elementPath = `cards/${elementId}`;
      } else {
        elementPath = `${moduleName}/${elementId}`;
      }
      const pageData = await nodeAxiosFirebase({
        t,
        url: 'getDocument',
        errorToast: t('errorLoadingDocument'),
        body: {
          documentPath: elementPath,
          structureId: structureId,
          lang: currentlangCode,
          document: elementData,
          height: window.innerHeight,
          device: 'desktop',
          eventData: {
            queryID: currentCollection?.[moduleName]?.queryID
              ? currentCollection?.[moduleName]?.queryID
              : null,
            type: 'click',
            objectIDs: currentCollection?.[moduleName]?.queryID
              ? currentCollection?.[moduleName]?.objectIDs
              : null,
          },
        },
      });
      setAccTabs(pageData || []);
      setIsLoading(false);
      setPageOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (elementId && structureId && elementData?.id === elementId) {
      let updatedBlockLayout = accTabs[activeIndex];
      updatedBlockLayout?.blocks?.forEach((block) => {
        if (block.match === 'id:id') {
          const correspondingData = block?.data;

          correspondingData.forEach((dataItem) => {
            dataItem.value = singleElement[dataItem.structureValue];
          });
        }
      });
      setAccTabs([
        ...accTabs.slice(0, activeIndex),
        updatedBlockLayout,
        ...accTabs.slice(activeIndex + 1),
      ]);
    }
  }, [singleElement?.lastUpdate]);

  const updateData = async () => {
    setEditMode(false);
    setTabDetailsOpen(false);

    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'updateBlock-Info',
          type: 'pulse',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        showLoading: true,
        url: `business-setModuleStructure`,
        body: {
          lang: currentlangCode,
          structureId: structureId,
          type: 'blocks-position',
          data: {
            tabIndex: activeIndex,
            blocksData: layout,
          },
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'updateBlock-Info',
          type: 'pulse',
        })
      );
      if (
        accTabs[activeIndex]?.groups !== tabData?.groups ||
        accTabs[activeIndex]?.name_en !== tabData?.name_en ||
        accTabs[activeIndex]?.name_fr !== tabData?.name_fr
      )
        dispatch(
          setGeneralStatus({
            status: 'loading',
            position: 'addTab',
            type: 'pulse',
          })
        );
      {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          showLoading: true,
          url: `business-setModuleStructure`,
          body: {
            structureId: structureId,
            lang: currentlangCode,
            type: 'tab',
            data: {
              tabIndex: activeIndex,
              tabData: {
                name_en: tabData?.name_en,
                name_fr: tabData?.name_fr,
                groups: tabData?.groups?.map((group) => group.value),
              },
            },
          },
        });
        dispatch(
          setGeneralStatus({
            status: 'success',
            position: 'addTab',
            type: 'pulse',
          })
        );
      }
      setManualIndex(null);
      setTabData(null);
      setTimeout(() => {
        getDocument();
      }, 700);
    } catch (error) {
      console.error('Error update module data', error);
    }
  };

  const handleAddBlock = async () => {
    const newBlock = {
      i: layout?.length.toString(),
      x: 0,
      y: 0,
      w: 5,
      h: 8,
      contentType: 'content',
      height: 200,
    };

    setLayout([...layout, newBlock]);
  };

  const updateTabIndexInURL = (index) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('tab', index);
    navigate(`${pathname}?${searchParams.toString()}`);
  };

  const handleAddTab = async () => {
    setManualIndex(accTabs?.length);
    setAccTabs([
      ...accTabs,
      {
        name: tabData?.[`name_${currentlangCode}`] || 'New Tab',
        blocks: [
          {
            i: '0',
            x: 0,
            y: 0,
            w: 5,
            h: 8,
            contentType: 'content',
            height: 200,
          },
        ],
      },
    ]);
    updateTabIndexInURL(accTabs?.length);
    setTabDetailsOpen(true);
  };

  const handleSaveAddTab = async () => {
    try {
      dispatch(
        setGeneralStatus({
          status: 'loading',
          position: 'updateLayout',
          type: 'backdrop',
        })
      );
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        showLoading: true,
        url: `business-setModuleStructure`,
        body: {
          structureId: structureId,
          type: 'addTab',
          lang: currentlangCode,
          data: {
            tabData: {
              name_en: tabData?.name_en,
              name_fr: tabData?.name_fr,
              groups: tabData?.groups?.map((group) => group.value),
            },
            tabBlocks: layout,
          },
        },
      });
      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'updateLayout',
          type: 'backdrop',
        })
      );
      setManualIndex(null);
      setEditMode(false);
      setTabDetailsOpen(false);
      setTimeout(() => {
        getDocument();
      }, 700);
    } catch (error) {
      console.error('Error update module data', error);
    }
  };

  useEffect(() => {
    if (elementId && structureId && elementData?.id === elementId) {
      getDocument();
    }
  }, [singleElement, elementId, structure, elementData?.id]);

  const layoutInit = accTabs[activeIndex]?.blocks || [];

  useEffect(() => {
    setLayout(layoutInit);
  }, [layoutInit]);

  useEffect(() => {
    if (elementId && structureId && businessPreference?.id) {
      let elementPath = null;

      if (elementData?.id !== elementId) {
        setIsLoading(true);
      }

      if (moduleName === 'contacts') {
        const targetId = elementId?.split(businessPreference?.id)[0];
        elementPath = `users/${targetId}/connections/${elementId}`;
      } else if (
        moduleName === 'cardsinvoiced' ||
        moduleName === 'cardsuninvoiced'
      ) {
        elementPath = `cards/${elementId}`;
      } else {
        elementPath = `${moduleName}/${elementId}`;
      }
      const elementRef = doc(db, elementPath);

      const unsubscribe = onSnapshot(
        elementRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const elementDataDoc = docSnapshot.data();

            setElementData({
              id: docSnapshot.id,
              documentPath:
                elementDataDoc?.documentPath || docSnapshot?.ref?.path,
              documentId: docSnapshot.id,
              ...elementDataDoc?.data,
              ...elementDataDoc,
              categoryId: elementDataDoc?.categoryId?.id || null,
              finances: {
                ...elementDataDoc?.finances,
                total: elementDataDoc?.finances?.total || null,
                subtotal: elementDataDoc?.finances?.subtotal || null,
                incomeLine: elementDataDoc?.finances?.incomeLine?.path || null,
                expenseLine:
                  elementDataDoc?.finances?.expenseLine?.path || null,
              },
              ownerId: elementDataDoc?.ownerId?.id,
              targetProfileId: elementDataDoc?.targetProfileId?.id || null,
              targetProfilePath: elementDataDoc?.targetProfileId?.path || null,
              targetId: elementDataDoc?.targetId?.id || null,
              assignedToId: elementDataDoc?.assignedToId?.id || '',
              targetPath: elementDataDoc?.targetId?.path || null,
              dependencyId: elementDataDoc?.dependencyId?.id || null,
              dependencyPath: elementDataDoc?.dependencyId?.path || null,
              structureId: elementDataDoc?.structureId?.id,
              structurePath: elementDataDoc?.structureId?.path || null,
              locationId: elementDataDoc?.locationId?.id || null,
              assignedToId: elementDataDoc?.assignedToId?.id || null,
              financesTotal: elementDataDoc?.finances?.total || null,
              financesSubtotal: elementDataDoc?.finances?.subtotal || null,
              financesAmount: elementDataDoc?.finances?.amount || null,
              targetProfileName:
                elementDataDoc?.targetProfileDetails?.name ||
                elementDataDoc?.targetProfileName ||
                null,
              targetName:
                elementDataDoc?.targetDetails?.name ||
                elementDataDoc?.targetName ||
                null,
              targetPhone: elementDataDoc?.targetDetails?.phone || null,
              targetEmail:
                elementDataDoc?.targetDetails?.email ||
                elementDataDoc?.targetEmail ||
                null,
              targetAddress:
                elementDataDoc?.targetDetails?.address ||
                elementDataDoc?.targetAddress ||
                null,
              dependencyName:
                elementDataDoc?.dependencyDetails?.name ||
                elementDataDoc?.dependencyName ||
                null,
              assignedToName:
                elementDataDoc?.assignedToDetails?.name ||
                elementDataDoc?.assignedToName ||
                null,
              userARef: null,
              userBRef: null,
            });
            dispatch(
              setSingleElementDetails({
                id: docSnapshot.id,
                documentPath:
                  elementDataDoc?.documentPath || docSnapshot?.ref?.path,
                documentId: docSnapshot.id,
                ...elementDataDoc?.data,
                ...elementDataDoc,
                categoryId: elementDataDoc?.categoryId?.id || null,
                businessId: businessPreference?.id,
                ownerId: elementDataDoc?.ownerId?.id,
                targetProfileId: elementDataDoc?.targetProfileId?.id || null,
                finances: {
                  ...elementDataDoc?.finances,
                  total: elementDataDoc?.finances?.total || null,
                  subtotal: elementDataDoc?.finances?.subtotal || null,
                  incomeLine:
                    elementDataDoc?.finances?.incomeLine?.path || null,
                  expenseLine:
                    elementDataDoc?.finances?.expenseLine?.path || null,
                },
                targetProfilePath:
                  elementDataDoc?.targetProfileId?.path || null,
                targetId: elementDataDoc?.targetId?.id || null,
                assignedToId: elementDataDoc?.assignedToId?.id || null,
                targetPath: elementDataDoc?.targetId?.path || null,
                dependencyId: elementDataDoc?.dependencyId?.id || null,
                dependencyPath: elementDataDoc?.dependencyId?.path || null,
                structureId: elementDataDoc?.structureId?.id,
                structurePath: elementDataDoc?.structureId?.path || null,
                locationId: elementDataDoc?.locationId?.id || null,
                assignedToId: elementDataDoc?.assignedToId?.id || null,
                userARef: null,
                userBRef: null,
              })
            );
          }
        },

        (error) => {
          console.error('Error in real-time data fetching:', error);
        }
      );

      return unsubscribe;
    }
  }, [elementId, structureId, businessPreference?.id]);

  useEffect(() => {
    if (elementData?.id === elementId) {
      const currentHistoryToAdd = {
        pathname: location.pathname,
        url: location.pathname + location.search,
        name: elementData?.name || elementData?.targetName,
        moduleName: structure?.name || '',
        structureId: structureId,
        businessId: businessPreference?.id,
        timeStamp: moment().format('HH:mm:ss'),
      };

      let existingHistory = JSON.parse(localStorage.getItem('history'));

      if (!Array.isArray(existingHistory)) {
        existingHistory = [];
      }

      // Remove any existing history with the same pathname
      existingHistory = existingHistory.filter(
        (history) => history.pathname !== location.pathname
      );

      const newHistory = [...existingHistory, currentHistoryToAdd];
      if (newHistory.length > 15) {
        newHistory.shift();
      }

      localStorage.setItem('history', JSON.stringify(newHistory));
    }
  }, [elementData]);

  const handleConfirmDelete = async () => {
    dispatch(
      modalActions.modalConfirmation({
        isOpen: true,
        title: t('deleteElement'),
        handleConfirm: () => handleElementDelete(),
      })
    );
  };

  const handleClose = () => {
    dispatch(drawerActions.viewElement({ isDrawerOpen: false }));
  };

  const handleOpenDrawer = () => {
    dispatch(
      drawerActions.viewElement({
        isDrawerOpen: true,
        item: elementData,
        handleDrawerClose: handleClose,
        type: 'edit',
      })
    );
  };

  //delete card
  const handleElementDelete = async () => {
    let formattedPath = '';
    try {
      if (moduleName === 'contacts') {
        const targetId = elementId?.split(businessPreference?.id)[0];
        dispatch(
          setGeneralStatus({
            status: 'loading',
            position: 'deleteCard-element',
            type: 'pulse',
          })
        );
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: 'deleteUser',
          showLoading: true,
          errorToast: t('makesureUserDelete'),
          body: {
            userId: targetId,
          },
        });
        dispatch(
          setGeneralStatus({
            status: 'success',
            position: 'deleteCard-element',
            type: 'pulse',
          })
        );
      } else {
        if (elementData?.documentPath) {
          const parts = elementData?.documentPath?.split('/');
          parts.pop();
          formattedPath = parts.join('/');
        }
        if (formattedPath)
          dispatch(
            setGeneralStatus({
              status: 'loading',
              position: 'deletesub-element',
              type: 'pulse',
            })
          );
        {
          await nodeAxiosFirebase({
            t,
            method: 'POST',
            url: 'deleteSubElementV2',
            errorToast: t('makesureElementsDelete'),
            showLoading: true,
            body: {
              elementPath: formattedPath,
              elementId: elementId,
            },
          });
          dispatch(
            setGeneralStatus({
              status: 'success',
              position: 'deletesub-element',
              type: 'pulse',
            })
          );
        }
      }
      navigateBack();
      dispatch(setGeneralStatus({ status: 'success' }));
    } catch (error) {
      dispatch(setGeneralStatus({ status: 'error', error: error }));
      console.error(error);
    }
  };

  const navigateBack = () => {
    const history = localStorage.getItem('history')
      ? JSON.parse(localStorage.getItem('history'))
      : [];
    const lastElementMinusOne = history[history?.length - 2];
    navigate(lastElementMinusOne?.url || lastElementMinusOne?.pathname);
  };

  const openEmailModal = () => {
    dispatch(
      modalActions.modalEmail({
        isOpen: true,
        type: pathname?.startsWith('/app/element/card') ? 'card' : '',
      })
    );
  };

  const tabs =
    (accTabs?.length > 0 &&
      accTabs?.map((tab, index) => ({
        label: tab?.name || 'tab' + index,
        value: index,
      }))) ||
    [];

  const handleEditLayout = () => {
    if (editMode === true) {
      if (manualIndex === null) {
        updateData();
      } else {
        handleSaveAddTab();
      }
    } else {
      setEditMode(true);
      const formattedGroups = accTabs[activeIndex]?.groups?.map((group) => ({
        label:
          businessPreference?.groups?.find((g) => g?.identifiant === group)
            ?.name || '',
        value: group,
        id: group,
      }));
      setTabData({
        groups: formattedGroups || [],
        name_en: accTabs[activeIndex]?.name_en || '',
        name_fr: accTabs[activeIndex]?.name_fr || '',
      });
      setTabDetailsOpen(true);
    }
  };
  dispatch(
    setGeneralStatus({
      status: 'loading',
      position: 'updateDate',
      type: 'pulse',
    })
  );
  const handleChangeDate = async (date) => {
    await nodeAxiosFirebase({
      t,
      method: 'POST',
      url: 'updateFieldV2',
      showLoading: true,
      body: {
        documentId: elementId,
        elementPath: 'cards/',
        key: elementData?.invoiceDate === null ? 'targetDate' : 'invoiceDate',
        value: date,
      },
    });
    dispatch(
      setGeneralStatus({
        status: 'success',
        position: 'updateDate',
        type: 'pulse',
      })
    );
  };

  const openModalPrint = () => {
    dispatch(
      modalActions.modalInvoice({
        isOpen: true,
      })
    );
  };

  const copyLink = () => {
    let link;
    if (elementData?.isProject) {
      link = `https://usenode.com/card-project/${businessPreference?.name}/${elementId}?accessToken=${elementData?.accessCode}&shared=true`;
    } else {
      link = `https://usenode.com/card/${businessPreference?.name}/${elementId}?accessToken=${elementData?.accessCode}&shared=true`;
    }
    navigator.clipboard.writeText(link);
    toast.success(t('copied'));
  };
  dispatch(
    setGeneralStatus({
      status: 'loading',
      position: 'updateElement',
      type: 'pulse',
    })
  );
  const handleConvert = async () => {
    setIsLoading(true);
    await nodeAxiosFirebase({
      t,
      method: 'POST',
      url: 'updateFieldV2',
      showLoading: true,
      body: {
        documentId: elementId,
        elementPath: 'cards/',
        key: 'isInvoiced',
        value: true,
      },
    });
    dispatch(
      setGeneralStatus({
        status: 'success',
        position: 'updateElement',
        type: 'pulse',
      })
    );

    setTimeout(() => {
      setIsLoading(false);
      navigate(
        `/app/element/cardsinvoiced/${structureIdInvoiced}/${elementId}`
      );
    }, 3200);
  };

  return (
    <MainLayoutV2
      actions={{
        email: structure?.element?.preferences?.email
          ? () => openEmailModal()
          : null,
        cells:
          layout?.[0]?.mainElement?.cellData?.length > 0 &&
          layout?.[0]?.mainElement?.cellData,
        tags: structure?.element?.preferences?.tags
          ? elementData?.tags || []
          : null,
        share: structure?.element?.preferences?.share ? () => copyLink() : null,
        onChangeDate: (e) => handleChangeDate(e.target.value),
        print: structure?.element?.preferences?.print
          ? () => openModalPrint()
          : null,
        date:
          (elementData?.invoiceDate?.seconds ||
            elementData?.invoiceDate?._seconds ||
            elementData?.targetDate?.seconds ||
            elementData?.targetDate?._seconds) &&
          moment
            .unix(
              elementData?.invoiceDate?.seconds ||
                elementData?.invoiceDate?._seconds ||
                elementData?.targetDate?.seconds ||
                elementData?.targetDate?._seconds
            )
            .format('YYYY-MM-DD'),
        deleteItem: structure?.element?.preferences?.delete
          ? elementData?.documentPath && handleConfirmDelete
          : null,
        editLayout: {
          action: handleEditLayout,
          display: editMode,
          addBlock: handleAddBlock,
          addTab: handleAddTab,
        },
        edit: structure?.element?.preferences?.edit ? handleOpenDrawer : null,
        isFeatured: structure?.element?.preferences?.favorite
          ? elementData?.isFeatured || false
          : null,
        convert: structure?.element?.preferences?.convert
          ? {
              action:
                !elementData?.isInvoiced &&
                elementData?.finances &&
                handleConvert,
              tooltip: t('convertToInvoice'),
            }
          : null,
      }}
      pageTitle={elementData?.name || elementData?.targetName || ''}
      elementId={elementId}
      manualIndex={manualIndex}
      formatedPath={elementData?.documentPath?.split('/' + elementId).join('')}
      sectionTitle={
        elementData?.isInvoiced
          ? ((elementData?.finances?.balance || 0) / 10000)?.toFixed(2) + ' $'
          : elementData?.searchId?.toUpperCase() ||
            elementData?.targetReference ||
            ''
      }
      selectedTab={activeIndex}
      tabs={tabs}
    >
      {elementData?.id === elementId && isLoading === false ? (
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <ElementDetailsContent
            elementData={elementData}
            editMode={editMode}
            currentElementId={elementId}
            elementId={elementId}
            layout={layout}
            activeIndex={activeIndex}
            refreshDoc={getDocument}
            setLayout={setLayout}
          />
          {tabDetailsOpen && (
            <div
              style={{
                position: 'fixed',
                bottom: 30,
                zIndex: 999999,
                width: '760px',
                alignContent: 'center',
                justifyContent: 'center',
                display: 'flex',
                backgroundColor: isDarkMode ? '#000' : '#FFF',
                borderRadius: '60px',
                marginLeft: '-60px',
                marginBottom: '-20px',
                paddingRight: '15px',
                paddingTop: '3px',
                paddingBottom: '8px',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
              }}
            >
              <div className="d-flex middle-content">
                <TextField
                  label={t('nameEn')}
                  fullWidth
                  value={tabData?.name_en}
                  onChange={(e) =>
                    setTabData({ ...tabData, name_en: e.target.value })
                  }
                  style={{ paddingRight: '10px' }}
                />
                <TextField
                  label={t('nameFr')}
                  fullWidth
                  value={tabData?.name_fr}
                  onChange={(e) =>
                    setTabData({ ...tabData, name_fr: e.target.value })
                  }
                  style={{ paddingRight: '10px' }}
                />
                <Autocomplete
                  multiple
                  fullWidth
                  options={formattedGroups || []}
                  getOptionLabel={(option) => option?.label || ''}
                  value={tabData?.groups}
                  onChange={(event, newValue) => {
                    setTabData({ ...tabData, groups: newValue });
                  }}
                  sx={{ maxWidth: '210px', marginTop: '5px' }}
                  renderInput={(params) => (
                    <TextFieldMUI
                      {...params}
                      sx={{
                        'MuiFormControl-root': {
                          width: '100%',
                          height: '55px',
                        },
                        '.MuiInputBase-input': {
                          padding: '2px 10px 2px 10px',
                          height: '55px',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '14px',
                          height: '55px',
                          '&.Mui-focused fieldset': {
                            borderColor:
                              businessPreference?.mainColor || '#000',
                            boxShadow: `0 0 0 0.2rem ${
                              businessPreference?.mainColor + '20'
                            }`,
                          },
                        },
                      }}
                      label={t('selectGroup')}
                      variant="outlined"
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex p-3">
          <div className="col-4">
            <Skeleton
              variant="rect"
              width="100%"
              height={'35vh'}
              sx={{ borderRadius: '12px' }}
            />
            <div className="mt-2">
              <Skeleton
                variant="rect"
                width="100%"
                height={'47vh'}
                sx={{ borderRadius: '12px' }}
              />
            </div>
          </div>
          <div className="col-5" style={{ paddingLeft: '10px' }}>
            <Skeleton
              variant="rect"
              width="100%"
              height={'40vh'}
              sx={{ borderRadius: '12px' }}
            />
            <div className="mt-2">
              <Skeleton
                variant="rect"
                width="100%"
                height={'42vh'}
                sx={{ borderRadius: '12px' }}
              />
            </div>
          </div>
          <div className="col-3" style={{ paddingLeft: '10px' }}>
            <Skeleton
              variant="rect"
              width="100%"
              height={'15vh'}
              sx={{ borderRadius: '12px' }}
            />{' '}
            <div className="mt-2">
              <Skeleton
                variant="rect"
                width="100%"
                height={'67vh'}
                sx={{ borderRadius: '12px' }}
              />
            </div>
          </div>
        </div>
      )}
    </MainLayoutV2>
  );
};

export default ElementDetails;
