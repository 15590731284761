import GeneralText from '../../../stories/general-components/GeneralText';
import { Divider } from '@mui/material';

const CardClientInformation = ({ cardData }) => {
  return (
    <div>
      <Divider component="div" />
      <div className="row mt-3 mb-4">
        <div className="col-6">
          <div>
            <GeneralText
              size="bold"
              primary={true}
              fontSize="12px"
              text={cardData?.targetDetails?.name}
            />
            {cardData?.targetDetails?.address && (
              <div className="mb-2">
                <GeneralText
                  size="regular"
                  primary={true}
                  fontSize="11px"
                  text={cardData?.targetDetails?.address}
                />
              </div>
            )}
            <GeneralText
              size="regular"
              primary={true}
              fontSize="11px"
              text={cardData?.targetDetails?.email || ''}
            />
            <GeneralText
              size="regular"
              primary={true}
              fontSize="11px"
              text={cardData?.targetDetails?.phone || ''}
            />
          </div>
        </div>
        <div className="col-6"></div>
      </div>
      <Divider component="div" />
    </div>
  );
};

export default CardClientInformation;
