// Libraries
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

const QuickNote = ({ elementPath, elementDetails, handleSave }) => {
  const { t } = useTranslation();
  const noteInputRef = useRef(null);
  const [note, setNote] = useState('');

  useEffect(() => {
    setNote(elementDetails);
  }, [elementPath]);

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const saveNote = async () => {
    handleSave('note', note);
  };

  return (
    <div style={{ maxWidth: '100%' }}>
      <TextField
        fullWidth
        variant="outlined"
        multiline
        placeholder={t('writeANote')}
        value={note}
        sx={{
          border: 'none',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent',
            },
            fontSize: '11px',
          },
        }}
        onChange={handleNoteChange}
        ref={noteInputRef}
        onBlur={saveNote}
      />
    </div>
  );
};

export default QuickNote;
