// Libraries
import React, { useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';

// Components
import TextField from '../../stories/general-components/TextField';
import DatePickerUTC from '../../stories/general-components/DatePickerUTC';
import TagSelector from '../../stories/general-components/TagSelector';
import IconUploader from './IconUploader';
import Select from '../../stories/general-components/Select';
import Checkbox from '../../stories/general-components/Checkbox';
import SelectAutoComplete from '../../stories/general-components/SelectAutoComplete';
import Selection from '../../stories/general-components/Selection';
import NodeDropdown from '../../stories/general-components/NodeDropdown';
import Search from '../../stories/general-components/Search';
import Slider from '../../stories/general-components/Slider';
import Geo from '../../stories/general-components/Geo';
import SelectAutoMulti from '../../stories/general-components/SelectAutoMulti';
import Signature from '../../stories/general-components/Signature';

const FieldComponent = ({
  field,
  value,
  key,
  staticField,
  onChange,
  defaultValue,
  parentElementCollection,
  parentElementPath,
  error,
  setParentElementCollection,
  setParentElementPath,
  handleSave,
  setCreationTargetId,
  creationTargetId,
  handleKeyPress,
  ref,
  fieldIndex,
  parentData,
  setParentData,
}) => {
  const handleFieldChange = useCallback(
    (event, fieldType, id, element) => {
      let value;

      switch (fieldType) {
        case 'number':
          value = Number(event.target.value);
          break;
        case 'boolean':
          value = event.target.checked;
          break;
        case 'date':
        case 'date-time':
          value = id;
          break;
        case 'node':
          value = element === 'users' ? `users/${id}` : id;
          break;
        case 'hook':
          value = element === 'profiles' ? `profiles/${id}` : id;
          break;
        case 'signature':
          value = event;
          break;
        default:
          value = event.target.value;
      }
      onChange(field?.value, value, fieldType, fieldIndex);
    },
    [field, fieldIndex, onChange]
  );

  const handleSearchChange = useCallback(
    (value, targetId) => {
      onChange(field.value, value);
      const valueId = targetId?.split('/')[1] || value?.split('/')[1];
      if (field.value === 'targetId' || field.value === 'dependencyId') {
        setCreationTargetId(valueId);
      }
    },
    [field.value, onChange, setCreationTargetId]
  );

  const addToParent = field?.value === 'georadius' ? true : false;

  const handleSelectChange = useCallback(
    (fieldId, value) => {
      onChange(fieldId, value);
      if (handleSave) {
        handleSave(fieldId, value);
      }
    },
    [handleSave, onChange]
  );

  const handleUploadComplete = useCallback(
    (files) => {
      const fileUrls = files?.map((file) => file.fileUrl);
      onChange(field.value, fileUrls);
    },
    [field.value, onChange]
  );

  useEffect(() => {
    if (ref?.current) {
      ref?.current?.focus();
    }
  }, [ref]);

  const handleFieldBlur = useCallback(
    (event, fieldType, id, element) => {
      if (handleSave) {
        let value;

        switch (fieldType) {
          case 'number':
            value = Number(event.target.value);
            break;
          case 'boolean':
            value = event.target.checked;
            break;
          case 'node':
            value = element === 'users' ? `users/${id}` : id;
            break;
          case 'signature':
            value = event;
            break;
          case 'date':
          case 'geo':
          case 'date-time':
            value = id;
            break;
          default:
            value = event.target.value;
        }
        handleSave(field?.value, value, fieldType);
      }
    },
    [field, handleSave]
  );

  const handleSelectBlur = useCallback(
    (fieldId, value) => {
      if (handleSave) {
        handleSave(fieldId, value);
      }
    },
    [handleSave]
  );

  const handleUploadBlur = useCallback(
    (files) => {
      if (handleSave) {
        const fileUrls = files?.map((file) => file.fileUrl);
        handleSave(
          field.value,
          field?.typeData === 'media-single' ? fileUrls[0] : fileUrls
        );
      }
    },
    [field, handleSave]
  );

  useEffect(() => {
    if (field?.typeData === 'date' && !value) {
      handleFieldChange(null, 'date', {
        seconds: moment().unix(),
        _seconds: moment().unix(),
      });
    }
  }, [field?.typeData, value, handleFieldChange]);

  useEffect(() => {
    if (field?.typeData === 'date-time' && !value) {
      handleFieldChange(null, 'date-time', {
        seconds: moment().unix(),
        _seconds: moment().unix(),
      });
    }
  }, [field?.typeData]);

  useEffect(() => {
    if (field?.typeData === 'selection' && !value && value !== 0) {
      handleSelectChange(field?.value, field?.selections[0]?.label);
    }

    if (field?.typeData === 'status' && !value && value !== 0) {
      handleSelectChange(field?.value, field?.selections[0]?.value);
    }
  }, [field?.typeData]);

  const renderField = useMemo(() => {
    const commonProps = {
      key: field?.value || key,
      error,
      required: field?.required || false,
      label: field?.name,
      mask: field?.mask,
      value,
      staticField,
      defaultValue,
      onBlur: handleFieldBlur,
      onChange: handleFieldChange,
      fieldType: field?.typeData,
      ref,
    };

    switch (field?.typeData) {
      case 'string':
      case 'text':
        return (
          <TextField
            {...commonProps}
            fullWidth
            validation={field?.validation || null}
            transform={field?.transform || null}
            handleKeyPress={handleKeyPress}
          />
        );
      case 'number':
      case 'money':
        return (
          <TextField
            {...commonProps}
            fullWidth
            type="number"
            handleKeyPress={handleKeyPress}
          />
        );
      case 'date':
      case 'date-time':
        return (
          <DatePickerUTC
            {...commonProps}
            fullWidth
            InputLabelProps={{ shrink: true }}
            timePicker={field?.typeData === 'date-time'}
          />
        );
      case 'boolean':
        return <Checkbox {...commonProps} fullWidth />;
      case 'media':
      case 'media-single':
        return (
          <IconUploader
            {...commonProps}
            elementId={field.value}
            elementType={field?.name}
            onComplete={handleUploadComplete}
            onBlur={handleUploadBlur}
          />
        );
      case 'status':
      case 'selection':
        return (
          <Selection
            {...commonProps}
            selections={field?.selections}
            onChange={handleSelectChange}
            onBlur={handleSelectBlur}
            field={field}
          />
        );
      case 'tags':
        return (
          <TagSelector
            {...commonProps}
            fullWidth
            onChange={handleSelectChange}
            onBlur={handleSelectBlur}
          />
        );
      case 'slider':
        return (
          <Slider
            {...commonProps}
            selections={field?.selections}
            onChange={handleSelectChange}
            onBlur={handleSelectBlur}
            field={field}
          />
        );
      case 'geo':
        return (
          <Geo
            {...commonProps}
            onChange={handleSelectChange}
            onBlur={handleSelectBlur}
            field={field}
            addToParent={addToParent}
            setParentData={setParentData}
          />
        );
      case 'search':
        return (
          <Search
            {...commonProps}
            fieldKey={field.value}
            fullWidth
            selections={field?.selections}
            onChange={handleSearchChange}
            setParentElementPath={setParentElementPath}
            setParentElementCollection={setParentElementCollection}
          />
        );
      case 'signature':
        return (
          <Signature
            {...commonProps}
            fieldKey={field.value}
            fullWidth
            onChange={handleFieldChange}
            onBlur={handleFieldBlur}
          />
        );
      case 'hook':
        return (
          <SelectAutoComplete
            {...commonProps}
            fieldKey={field?.value}
            creationTargetId={creationTargetId}
            selections={field?.selections}
            parentElementCollection={parentElementCollection}
            parentElementPath={parentElementPath}
            noEmpty
          />
        );
      case 'hook-multi':
        return (
          <SelectAutoMulti
            {...commonProps}
            fieldKey={field?.value}
            creationTargetId={creationTargetId}
            selections={field?.selections}
            field={field}
            onChange={handleSearchChange}
            parentData={parentData}
          />
        );
      case 'dropdown':
        return (
          <Select
            {...commonProps}
            defaultValue={value}
            selections={field?.selections}
            noEmpty
          />
        );
      case 'node':
        return (
          <NodeDropdown
            {...commonProps}
            defaultValue={defaultValue}
            selections={field?.selections}
            noEmpty
          />
        );
      default:
        console.warn(`Unsupported field type: ${field?.typeData}`);
        return null;
    }
  }, [
    field,
    value,
    key,
    error,
    staticField,
    defaultValue,
    handleFieldBlur,
    handleFieldChange,
    handleSelectChange,
    handleSelectBlur,
    handleUploadComplete,
    handleUploadBlur,
    handleSearchChange,
    setParentElementPath,
    setParentElementCollection,
    creationTargetId,
    parentElementCollection,
    parentElementPath,
    handleKeyPress,
    ref,
  ]);

  return renderField;
};

export default FieldComponent;
