import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import * as Icons from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Modules = ({
  businessModules,
  isTablet,
  activeModule,
  isDarkMode,
  businessPreference,
  handleEditModule,
  currentSectionState,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { segmentId } = useParams();
  const pageLoaded = useSelector((state) => state.core.pageLoaded);
  const activeSegment = activeModule?.segments?.find((s) => s.id === segmentId);

  const RestrictedModule = ({ children }) => {
    if (
      currentSectionState === 'SETTINGS' ||
      currentSectionState === 'TOOLS' ||
      currentSectionState === 'NODE'
    ) {
      return null;
    }
    return children;
  };

  const handleClickSegment = (finalSettingsUrl, segment) => {
    const url = finalSettingsUrl + '/' + segment.id;
    navigate(url);
  };

  return (
    <>
      {businessModules?.map((module, index) => {
        const IconComponent = Icons[module.icon] || Icons.Error;
        const finalSettingsUrl = '/app' + module?.url;

        // Animation settings
        const animationProps = {
          initial:
            pageLoaded == true ? { opacity: 1, y: 0 } : { opacity: 0, x: -20 },
          animate:
            pageLoaded == true ? { opacity: 1, y: 0 } : { opacity: 1, x: 0 },
          transition:
            pageLoaded == true ? {} : { delay: index * 0.2, duration: 0.5 },
        };

        return (
          <motion.div
            key={module.id + index + 'module'}
            style={{
              paddingTop: '0.15rem',
              paddingBottom: '0.15rem',
            }}
            {...animationProps}
          >
            <div
              style={{
                marginRight: '7px',
                borderRadius: '10px',
                border:
                  activeModule?.id === module?.id
                    ? `1px solid rgba(255, 255, 255, 0.3)`
                    : 'none',
                backgroundColor:
                  activeModule?.id === module?.id ? '#FFFFFF40' : 'transparent',
              }}
            >
              <div
                style={{
                  padding: '6px',
                }}
                className={` ${
                  isTablet ? 'd-flex align-c' : 'd-flex'
                } hover align-c`}
              >
                <div
                  onClick={() => navigate(finalSettingsUrl)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    height: '100%',
                  }}
                >
                  <Tooltip title={t(module.name)}>
                    <IconComponent
                      htmlColor={
                        activeModule?.id === module?.id
                          ? isDarkMode
                            ? '#FFF'
                            : `${businessPreference?.mainColor}`
                          : isDarkMode
                          ? '#FFF'
                          : '#000'
                      }
                      sx={{
                        paddingRight: isTablet ? '1px' : '3px',
                        fontSize: '23px',
                      }}
                    />
                  </Tooltip>
                </div>
                {!isTablet && (
                  <div
                    style={{ marginTop: '2px' }}
                    className="row d-flex parent-element justify-content-between px-3"
                  >
                    <div className="col-10 align-left ">
                      <Typography
                        onClick={() => navigate(finalSettingsUrl)}
                        fontWeight={activeModule?.id === module?.id ? 600 : 500}
                        fontSize="14px"
                        sx={{
                          '&:hover': {
                            fontWeight: 700,
                          },
                        }}
                        color={
                          activeModule?.id === module?.id
                            ? isDarkMode
                              ? '#FFF'
                              : `${businessPreference?.mainColor}`
                            : isDarkMode
                            ? '#FFF'
                            : '#000'
                        }
                        variant="body2"
                      >
                        {module?.name}
                      </Typography>
                      {!isTablet && (
                        <>
                          {module?.segments && module?.segments?.length > 0 && (
                            <div>
                              {module?.segments?.map((segment, index) => (
                                <div
                                  key={index + 'segment'}
                                  className="align-left"
                                  onClick={() =>
                                    handleClickSegment(
                                      finalSettingsUrl,
                                      segment
                                    )
                                  }
                                >
                                  <div className="d-flex middle-content">
                                    {activeSegment?.id === segment?.id && (
                                      <Icons.FilterListOutlined
                                        fontSize="10px"
                                        sx={{
                                          marginRight: '5px',
                                          marginLeft: '-20px',
                                          marginTop: '-1px',
                                        }}
                                      />
                                    )}
                                    <Typography
                                      fontWeight={
                                        activeSegment?.id === segment?.id
                                          ? 500
                                          : 400
                                      }
                                      fontSize="10px"
                                      className="hover"
                                      sx={{
                                        '&:hover': {
                                          fontWeight: 500,
                                        },
                                      }}
                                      color={
                                        isDarkMode ? '#FFFFFF95' : '#00000095'
                                      }
                                      variant="body2"
                                    >
                                      {segment?.name}
                                    </Typography>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <RestrictedModule>
                      <div
                        onClick={() => handleEditModule(module)}
                        className="col-2 align-right settings-icon-container"
                      >
                        <Icons.TuneOutlined
                          fontSize="10px"
                          htmlColor="lightgray"
                          sx={{
                            marginTop: '3px',
                            '&:hover': {
                              color: isDarkMode
                                ? '#FFF'
                                : `${businessPreference?.mainColor}`,
                            },
                          }}
                        />
                      </div>
                    </RestrictedModule>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        );
      })}
    </>
  );
};

export default Modules;
