import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

//utils
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { debounce } from 'lodash';
import {
  handleListData,
  searchDataSuccess,
} from '../../../../redux/actions-v2/listAction';
import { FormControl, InputAdornment, Input } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ResultsComponent from './ResultsComponent';

export const MainSearch = ({
  fromContent,
  onSelectReturn,
  structureIden,
  structureCollection,
  setClicked,
  clicked,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { moduleName, moduleId, structureId } = useParams();
  const mode = localStorage.getItem('mode') || 'light';

  const currentPath = window.location.pathname;

  const [inputSearchValue, setInputSearchValue] = useState('');
  const [moduleNameIden, setModuleNameIden] = useState(
    structureCollection || moduleName
  );

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const activeCards = businessStructure?.structures?.find(
    (s) => s.collectionField === 'cardsuninvoiced'
  );

  const activeInvoices = businessStructure?.structures?.find(
    (s) => s.collectionField === 'cardsinvoiced'
  );

  //performsa debounced search
  const debouncedSearch = useCallback(
    debounce((searchValue) => onSearch(searchValue), 400),
    [structureId]
  );

  const handleInputChange = (event) => {
    setInputSearchValue(event.target.value);
    debouncedSearch(event.target.value);
  };

  const handleClickInput = () => {
    setInputSearchValue('');
    setTimeout(() => {
      setClicked(!clicked);
    }, 200);
  };

  const businessModules = businessStructure?.modules;
  const activeModule = businessModules?.find((m) => m.id === moduleId);

  const pageSize = activeModule?.list?.preferences?.limit;

  const listReducer = useSelector((state) => state.list.data);

  useEffect(() => {
    setInputSearchValue('');
  }, [moduleId]);

  //performs an search on selected category
  const onSearch = async (searchValue) => {
    if (
      pageSize === 0 ||
      moduleNameIden === 'employees' ||
      moduleNameIden === 'payments' ||
      moduleNameIden === 'items'
    ) {
      const filteredList = listReducer?.[moduleNameIden]?.filter((item) => {
        return (
          moduleNameIden === 'employees' ? item?.displayName : item?.targetName
        )
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase());
      });

      dispatch(searchDataSuccess(filteredList, moduleNameIden));
    } else {
      checkForReduxDispatch(searchValue);
    }
  };

  const structureIdentifier = structureIden
    ? structureIden
    : currentPath === '/app/finances/sales'
    ? activeInvoices?.id
    : currentPath === ' /app/finances/quotes'
    ? activeCards?.id
    : structureId;

  //used to check if we're searching for specific things on that specific page,
  //if so, we dispatch the redux action to get the results from algolia and don't show dropdown
  const checkForReduxDispatch = (searchValue) => {
    dispatch(
      handleListData(
        searchValue,
        structureIdentifier,
        moduleId,
        null,
        null,
        null,
        null,
        null,
        t,
        null,
        null,
        0
      )
    );

    return;
  };

  return (
    <div className="middle-content">
      <div className="col-12">
        <motion.div
          initial={{ width: '15vh' }}
          animate={{ width: clicked ? '20vh' : '15vh' }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          <FormControl
            sx={{
              backgroundColor: '#FFF',
              borderRadius: '30px',
              minWidth: '100%',
              border: 'none',
              '.MuiInputBase-input': {
                border: 'none',
              },
              '& .MuiFormLabel-root': {
                border: 'none',
              },
              '& .MuiInput-underline:before': {
                border: 'none',
              },
              '& .MuiInput-underline:after': {
                border: 'none',
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                border: 'none',
              },
              '& .MuiInput-underline:hover:not(.Mui-disabled):after': {
                border: 'none',
              },
            }}
            fullWidth
          >
            <Input
              value={inputSearchValue}
              onChange={handleInputChange}
              placeholder={t('search')}
              sx={{
                borderRadius: '30px',
                height: '45px',
                padding: '10px',
                fontSize: '13px',
              }}
              onFocus={handleClickInput}
              onBlur={handleClickInput}
              color="primary"
              margin="none"
              startAdornment={
                <InputAdornment sx={{ marginLeft: '5px' }} position="start">
                  <SearchOutlinedIcon
                    color="black"
                    sx={{
                      fontSize: '21px',
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        </motion.div>
      </div>
      {fromContent && inputSearchValue && (
        <div className="col-12">
          <ResultsComponent
            results={listReducer?.[moduleNameIden]}
            selectedType={moduleNameIden}
            onSelectReturn={onSelectReturn}
            fromVariable={fromContent}
          />
        </div>
      )}
    </div>
  );
};

export default MainSearch;
