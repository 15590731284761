import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { doc, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import { useTranslation } from 'react-i18next';
import PublicLayout from '../../layouts/PublicLayout';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { use } from 'i18next';
import chroma from 'chroma-js';

const GamePlay = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();
  const { gameId } = useParams();

  const [gameData, setGameData] = useState(null);
  const [playersData, setPlayersData] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const mainColor = gameData?.completedStepColor || '#FFD700';

  // Fetch game data
  useEffect(() => {
    const gameRef = doc(db, 'games', gameId);

    // Listen for real-time updates to the game document
    const unsubscribeGame = onSnapshot(gameRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const docData = docSnapshot.data();
        console.log('Game Data:', docData);
        setGameData(docData);
      }
    });

    return () => unsubscribeGame();
  }, [gameId]);

  // Fetch players data from the players subcollection
  useEffect(() => {
    const playersRef = collection(db, 'games', gameId, 'players');

    const unsubscribePlayers = onSnapshot(playersRef, (snapshot) => {
      const playersList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log('Players Data:', playersList);

      // Check if any player's score has changed
      const currentPlayer = playersList.find(
        (player) =>
          player.score !== playersData.find((p) => p.id === player.id)?.score
      );

      if (currentPlayer) {
        setCurrentPlayer(currentPlayer);
      }

      setPlayersData(playersList);
    });

    return () => unsubscribePlayers();
  }, [gameId, playersData]);

  // Reset currentPlayer after 3 seconds to stop the shake
  useEffect(() => {
    if (currentPlayer) {
      const timer = setTimeout(() => {
        setCurrentPlayer(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [currentPlayer]);

  return (
    <PublicLayout>
      <Helmet>
        <title>{gameData?.name || ''} - Node Dots</title>
      </Helmet>
      <Container className="mt-5">
        <Typography
          variant="h1"
          align="center"
          fontSize={20}
          fontWeight={600}
          gutterBottom
        >
          {gameData?.name || 'No title'}
        </Typography>
        {gameData && gameData?.questions && playersData.length > 0 ? (
          <TableContainer elevation={0} className="mt-5" component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Player</TableCell>
                  {gameData?.questions.map((question, qIndex) => (
                    <TableCell key={qIndex}>
                      {question?.['title_' + currentLang]}
                    </TableCell>
                  ))}
                  <TableCell>Score</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {playersData.map((player, pIndex) => {
                  const gameCompleted =
                    player?.score === gameData?.questions?.length;

                  const ratio = player?.score / gameData?.questions?.length;
                  const color = chroma(mainColor)
                    .darken(ratio * 1.2)
                    .hex();

                  return (
                    <TableRow key={pIndex} style={{ height: '35px' }}>
                      <TableCell style={{ padding: '5px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            className={
                              currentPlayer?.id === player.id
                                ? 'subtle-shake'
                                : ''
                            }
                            src={player?.avatar}
                            alt={player?.name}
                            style={{
                              width: 35,
                              height: 35,
                              borderRadius: '50%',
                              marginRight: 10,
                            }}
                          />
                          {player?.name}
                        </div>
                      </TableCell>
                      {gameData?.questions?.map((question, qIndex) => (
                        <TableCell
                          key={qIndex}
                          style={{
                            borderRadius:
                              qIndex + 1 === player.score
                                ? '0px 30px 30px 0px'
                                : '0',
                            backgroundColor:
                              player.score > qIndex ? color : 'white',
                          }}
                        >
                          {player?.score > qIndex ? t('done') : 'noAnswer'}
                        </TableCell>
                      ))}
                      <TableCell>{player?.score}</TableCell>
                      <TableCell>
                        {gameCompleted ? t('completed') : 'inProgress'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" align="center">
            Loading game data...
          </Typography>
        )}
      </Container>
    </PublicLayout>
  );
};

export default GamePlay;
