import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

//components
import BusinessInformationTag from '../../components/@generalComponents/BusinessInformationTag';
import CardInformationTag from '../../components/@generalComponents/CardInformationTag';
import CardPayments from '../../components/finances/invoice/CardPayments';
import CardClientInformation from '../../components/finances/invoice/CardClientInformation';
import CardItems from '../../components/finances/invoice/CardItems';
import CardPasses from '../../components/finances/invoice/CardPasses';
import GeneralText from '../../stories/general-components/GeneralText';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import CardTasks from '../../components/finances/invoice/CardTasks';
import CardStorages from '../../components/finances/invoice/CardStorages';
import CardNodies from '../../components/finances/invoice/CardNodies';
import CardFiles from '../../components/finances/invoice/CardFiles';
import { Divider, Drawer, Tabs, Tab, CardMedia } from '@mui/material';
import Button from '../../stories/general-components/Button';
import Loading from '../../stories/general-components/Loading';
import CheckoutForm from './Payment';

const PublicInvoiceComponent = ({
  fromBusiness,
  accessCode,
  printRef,
  reload,
  setReload,
}) => {
  const componentRef = useRef(null);
  const { elementId } = useParams();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const langCode = i18n.language || 'fr';

  const from = new URLSearchParams(search).get('from');

  const [cardData, setCardData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [clientSecret, setClientSecret] = useState('');

  const stripePromise = loadStripe(
    'pk_live_51PpVVc039uUr8lBdyHrPXPgJbBDldnuBHEMqvbu3I0q05aWx5lQQrVpUEuo3qGEtWcSjx3TFkjdDHr0RYOKbn0fZ0029TBJ0u4'
  );

  useEffect(() => {
    if (
      cardData?.finances?.balance &&
      cardData?.businessData?.approvalActivated
    ) {
      const createIntent = async () => {
        try {
          const intent = await nodeAxiosFirebase({
            t,
            method: 'POST',
            url: `payments-processPaymentNode`,
            noAuth: true,
            body: {
              type: 'validate',
              cardId: elementId,
              amount: cardData?.finances?.balance,
            },
            reduxDispatcher: dispatch,
            loadingMessage: `${t('gettingCard')}`,
          });
          setClientSecret(intent?.client_secret);
        } catch (error) {
          console.error(error);
        }
      };
      createIntent();
    }
  }, [cardData]);

  const navigateHome = () => {
    window.location.href = '/';
  };

  const getCardDetails = async () => {
    setIsLoading(true);
    try {
      const card = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `cards-getSingle`,
        noAuth: true,
        body: {
          cardId: elementId,
          fromBusiness: fromBusiness,
          accessCode: accessCode,
          lang: langCode,
        },
        reduxDispatcher: dispatch,
        loadingMessage: `${t('gettingCard')}`,
      });
      setLogs(card?.logs);
      setCardData(card);
      setIsLoading(false);
      setReload(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCardDetails();
  }, [elementId, reload]);

  const approveAction = async () => {
    if (
      cardData?.businessData?.approvalActivated &&
      cardData?.businessData?.flowOnApprove === 'convert:invoice-payment'
    ) {
      setDrawerOpen(true);
    }
  };

  const options = {
    clientSecret: clientSecret,
    appearance: {
      /*...*/
    },
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <div>
      {isLoading && <Loading />}

      <div
        style={{ overflow: 'scroll', height: '90vh', paddingBottom: '40px' }}
        className="mt-4 mb-3 justify-content-between align-left"
      >
        {drawerOpen && (
          <Drawer anchor="bottom" open={drawerOpen} onClose={handleCloseDrawer}>
            <div className="p-5 hei-9 align-c row">
              <GeneralText
                primary={true}
                size="bold"
                fontSize="16px"
                text={`${t('payment')}`}
                classNameComponent="px-3 mb-5"
              />

              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
              </Elements>
            </div>
          </Drawer>
        )}
        {cardData?.id === elementId && (
          <div>
            {isLoading ? (
              <div>
                <Loading size="small" type="logo" />
              </div>
            ) : (
              <div className="card-print" ref={printRef || componentRef}>
                <div className="row mb-1 mt-3 px-4">
                  <div className="col-md-6 col-6 mt-2 ">
                    <BusinessInformationTag
                      businessData={cardData?.businessData}
                    />
                  </div>
                  <div className="col-md-6 col-6 mt-2 align--right">
                    <CardInformationTag
                      card={cardData}
                      from={from}
                      langCode={langCode || 'fr'}
                    />
                  </div>
                </div>
                <div className="mt-2 mb-1 px-4 align-left">
                  <CardClientInformation cardData={cardData} />
                </div>
                <div className="px-4">
                  {cardData?.note &&
                    cardData?.businessData?.view !== 'tabs-default' && (
                      <>
                        <p className="fs-11 fw-600">{t('details')}</p>
                        <p style={{ marginTop: '-6px' }} className="fs-10">
                          {cardData?.note}
                        </p>
                      </>
                    )}
                </div>
                {cardData?.isInvoiced === true &&
                  cardData?.businessData?.approvalActivated &&
                  Number(cardData?.finances?.balance?.toFixed(0)) > 0 &&
                  !fromBusiness && (
                    <div className="mb-2 mt-4 px-4 align-c">
                      <div className="mt-2 mb-2 fs-12 d-flex middle-content ">
                        <Button
                          label={t('addPayment')}
                          primary={true}
                          endIcon={'MonetizationOnOutlined'}
                          size="medium"
                          buttonSx={{
                            backgroundColor: '#62a751',
                            margin: '5px',
                          }}
                          disabled={
                            cardData?.status ===
                            parseInt(cardData?.businessData?.approvalStatus)
                          }
                          onClick={approveAction}
                        />{' '}
                      </div>
                      <Divider component="div" />
                    </div>
                  )}
                {cardData?.items && (
                  <div className="mb-2 mt-4 px-2 ">
                    <CardItems
                      items={cardData?.items}
                      card={cardData}
                      includeGroupsTotal={cardData?.preferences?.groups}
                    />
                  </div>
                )}

                {cardData?.isInvoiced && (
                  <div className="row px-4 mb-4">
                    <GeneralText
                      primary={true}
                      size="bold"
                      fontSize="13px"
                      text={`${t('payments')}`}
                    />
                    <CardPayments payments={cardData?.payments} />
                  </div>
                )}

                {cardData?.preferences?.passes && (
                  <div className="row px-4 mb-4">
                    <GeneralText
                      primary={true}
                      size="bold"
                      fontSize="13px"
                      text={`${t('events')}`}
                    />
                    <CardPasses items={cardData?.passes} />
                  </div>
                )}

                {cardData?.preferences?.nodies && (
                  <div className="row px-4 mb-4">
                    <GeneralText
                      primary={true}
                      size="bold"
                      fontSize="13px"
                      text={`${t('nodies')}`}
                    />
                    <CardNodies items={cardData?.nodies} />
                  </div>
                )}

                {cardData?.preferences?.tasks && (
                  <div className="row px-4 mb-4">
                    {' '}
                    <GeneralText
                      primary={true}
                      size="bold"
                      fontSize="13px"
                      text={`${t('logs')}`}
                    />
                    <CardTasks items={cardData?.tasks || []} />
                  </div>
                )}

                {cardData?.preferences?.storages && (
                  <div className="row px-4 mb-4">
                    {' '}
                    <GeneralText
                      primary={true}
                      size="bold"
                      fontSize="13px"
                      text={`${t('data')}`}
                    />
                    <CardStorages items={cardData?.storages || []} />
                  </div>
                )}

                {cardData?.preferences?.files && (
                  <div className="row px-4 mb-4">
                    {' '}
                    <GeneralText
                      primary={true}
                      size="bold"
                      fontSize="13px"
                      text={`${t('files')}`}
                    />
                    <CardFiles items={cardData?.files || []} />
                  </div>
                )}
                {(cardData?.businessData?.alternativeText ||
                  cardData?.businessData?.bottomText) && (
                  <div className="align-left px-4 mt-5">
                    <GeneralText
                      primary={true}
                      size="bold"
                      fontSize="13px"
                      text={`${t('notes')}`}
                    />
                    <GeneralText
                      primary={true}
                      markdown
                      fontSize="11px"
                      text={cardData?.businessData?.alternativeText || ''}
                    />
                    <GeneralText
                      primary={true}
                      markdown
                      fontSize="11px"
                      text={cardData?.businessData?.bottomText || ''}
                      classNameComponent="mt-3"
                    />
                  </div>
                )}
                <Divider component="div" className="px-3" />
                <div
                  className="mb-2 px-4 mt-5 align-left"
                  onClick={navigateHome}
                >
                  {' '}
                  <div className="d-flex mb-2">
                    <img
                      src="/assets/v3/img/icon-node-grey.png"
                      width={67}
                      height={16}
                      className="hover mt-2"
                      alt="Node"
                      style={{
                        opacity: 0.6,
                      }}
                    />
                    <div>
                      <GeneralText
                        primary={true}
                        fontSize="8px"
                        classNameComponent="grey-text hover px-3"
                        text={'Intelligence Node Canada™'}
                      />
                      <div
                        style={{
                          marginTop: '-6px',
                        }}
                      >
                        <GeneralText
                          primary={true}
                          fontSize="8px"
                          classNameComponent="grey-text hover px-3"
                          text={
                            t('allRightsReserved') +
                            ' - ' +
                            new Date().getFullYear()
                          }
                        />{' '}
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <GeneralText
                      primary={true}
                      fontSize="8px"
                      classNameComponent="grey-text"
                      text={t('bottom_invoice_text')}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicInvoiceComponent;
