//Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
//Components
import { Typography, Box } from '@mui/material';

const CardFiles = ({ items }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-4">
      {items?.length === 0 && (
        <Typography
          variant="h6"
          sx={{ fontSize: '12px', marginLeft: '15px', fontWeight: 500, mb: 2 }}
        >
          {t('noData')}
        </Typography>
      )}

      <Box sx={{ width: '100%' }}>
        <div className="d-flex hover">
          {items?.map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt={'file'}
              style={{
                width: '65px',
                height: '65px',
                borderRadius: '8px',
                marginRight: '5px',
              }}
            />
          ))}
        </div>
      </Box>
    </div>
  );
};

export default CardFiles;
